import clsx from 'clsx';
import React from 'react';

import { theme } from '@headway/helix/theme';

type BreakpointKey = keyof typeof theme.breakpoints;

export const LandingPageSectionContainer: React.FC<
  React.PropsWithChildren<{
    id?: string;
  }>
> = ({ id, children }) => {
  return (
    <div className="w-full overflow-hidden">
      <div
        id={id}
        className={`tablet:py-[6rem] mx-auto my-0 w-full max-w-[1200px] px-6 py-[5rem]`}
      >
        {children}
      </div>
    </div>
  );
};

export const LandingPageSection: React.FC<
  React.PropsWithChildren<{
    centered?: boolean;
    maxCenteredBreakpoint?: BreakpointKey;
  }>
> = ({ children, centered = false, maxCenteredBreakpoint }) => {
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-center',
        centered && 'items-center text-center',
        maxCenteredBreakpoint &&
          `max-${maxCenteredBreakpoint}:items-center max-${maxCenteredBreakpoint}:text-center`
      )}
    >
      <section
        className={clsx(
          `tablet:gap-y-8 grid w-full grid-cols-1 grid-rows-1 gap-x-0 gap-y-6 text-left`,
          (centered || maxCenteredBreakpoint) && 'm-auto text-center',
          maxCenteredBreakpoint && `max-${maxCenteredBreakpoint}:text-center`
        )}
      >
        {children}
      </section>
    </div>
  );
};

export const LandingPageSectionPair: React.FC<
  React.PropsWithChildren<{
    children: [
      React.ReactElement<typeof LandingPageSection>,
      React.ReactElement<typeof LandingPageSection>,
    ];
    reverse?: boolean;
  }>
> = ({ children, reverse = false }) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 gap-x-0 gap-y-12 [&>div]:order-none [&>div]:p-0',
        reverse &&
          `tablet:[&>:first-child]:order-2 tablet:[&>:last-child]:order-1`,

        `tablet:gap-y-0 tablet:gap-x-12 tablet:grid-cols-2`
      )}
    >
      {children}
    </div>
  );
};
