import { css } from '@emotion/react';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { InferGetServerSidePropsType } from 'next';
import { NextSeo } from 'next-seo';
import React, { useEffect, useState } from 'react';
import { useInRouterContext } from 'react-router-dom';
import { Organization, WithContext } from 'schema-dts';

import { ProviderSearchTopic } from '@headway/api/models/ProviderSearchTopic';
import { ProviderSearchTopicResponse } from '@headway/api/models/ProviderSearchTopicResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { ProviderSearchTopicApi } from '@headway/api/resources/ProviderSearchTopicApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { useFlagsClient } from '@headway/feature-flags/react';
import { Banner } from '@headway/helix/Banner';
import { Button } from '@headway/helix/Button';
import { ContentText } from '@headway/helix/ContentText';
import { HeadlineText } from '@headway/helix/HeadlineText';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme as helixTheme } from '@headway/helix/theme';
import {
  LandingPageSection,
  LandingPageSectionContainer,
  LandingPageSectionPair,
} from '@headway/patient-marketing/components';
import imgHikingSrc from '@headway/shared/assets/img/illustrations/Hiking.jpg';
import imgTeaSrc from '@headway/shared/assets/img/illustrations/Tea.jpg';
import imgWateringSrc from '@headway/shared/assets/img/illustrations/Watering.jpg';
import { useActiveUserFreezes } from '@headway/shared/hooks/useUserFreezes';
import { trackPageView } from '@headway/shared/utils/analytics';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { Carousel } from '@headway/ui/Carousel';
import { ListItem, OrderedList, PrimaryBackground } from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import {
  CoordinationOfBenefitsFreezeMessage,
  OtherFreezeMessage,
  OutOfNetworkFreezeMessage,
} from '../components/FreezeMessage';
import GetStartedContainer from '../components/GetStarted/GetStartedContainer';
import { GetStartedButton } from '../components/GetStartedButton';
import { Image } from '../components/Image/Image';
import { PageWrapper } from '../components/Nav/PageWrapper';
import { PayerPartners } from '../components/Payers/PayerPartners';
import { ProviderSearchByName } from '../components/ProviderSearchByName';
import { ProviderSearchTopicCard } from '../components/ProviderSearchTopicCard/ProviderSearchTopicCard';
import { TwitterTestimonialCarousel } from '../components/TwitterTestimonialCarousel';
import { IAuthStore, withStores } from '../stores/withStores';
import {
  getAuthCookie,
  ServerSideRequestWithUserMeData,
  ServerSideResponse,
} from '../utils/cookie';
import { getFullURLForPath } from '../utils/request';
import {
  SearchEntry,
  trackProviderSearchStarted,
} from '../utils/searchAnalyticEvents';

const organizationStructuredData: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  image: 'https://assets.headway.co/img/headway-logo.png',
  url: 'https://headway.co/',
  logo: 'https://assets.headway.co/img/headway-logo.png',
  name: 'Headway',
  description: `Headway is building a new mental health care system that everyone can access by making it easy for therapists to take insurance and scale their practice. Today, we're the largest mental health provider network in the country, with 34,000 providers across race, gender, ethnicity and specialty working with Headway to run their private practice. Patients can schedule care within 48 hours via one-click booking at headway.co, or can get care through a referral. The company operates in all 50 states and the District of Columbia.`,
  foundingDate: '2018',
  legalName: 'TherapyMatch, Inc',
  sameAs: [
    'linkedin.com/company/find-headway-com/',
    'facebook.com/findheadway/',
    'x.com/try_headway',
    'instagram.com/headway',
    'trustpilot.com/review/headway.co',
  ],
};

type IndexPageProps = {
  AuthStore: IAuthStore;
  query?: any;
} & InferGetServerSidePropsType<typeof getServerSideProps>;

export const getServerSideProps = async ({
  req,
  res,
}: {
  req: ServerSideRequestWithUserMeData;
  res: ServerSideResponse;
}) => {
  const cookie = getAuthCookie(req, res);

  const axiosConfig: AxiosRequestConfig = { headers: cookie ? { cookie } : {} };

  const [topics, user] = await Promise.all([
    ProviderSearchTopicApi.getProviderSearchTopics(undefined, axiosConfig),
    req.userMeData
      ? Promise.resolve(req.userMeData)
      : UserApi.getUserMe({}, axiosConfig),
  ]);

  const shouldShowAnthemEAPExperience =
    await UserApi.shouldShowAnthemEapExperience(
      user.id,
      { appointment_billing_type: undefined, provider_id: undefined },
      axiosConfig
    );

  return {
    props: {
      topics,
      shouldShowAnthemEAPExperience,
    },
  };
};

const IndexPageImpl = (props: IndexPageProps) => {
  const [showSearchByName, setShowSearchByName] = useState(false);

  useEffect(() => {
    trackPageView({ name: 'Homepage', properties: {} });
  }, []);

  const ldClient = useFlagsClient();

  const isRemix = useInRouterContext();

  useEffect(() => {
    if (
      (process.env.NEXT_PUBLIC_ENVIRONMENT === 'test' ||
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') &&
      typeof window !== 'undefined' &&
      ldClient
    ) {
      // Expose the launchdarkly client to the window object for use in Cypress tests
      // @ts-ignore
      window.__ldClient = ldClient;
    }
  }, [ldClient]);

  const { data: userFreezeReasons = [] } = useActiveUserFreezes(
    props.AuthStore.user.id
  );
  const hasCobFreeze = userFreezeReasons.includes(
    UserFreezeReason.COB_NEEDS_PAYER_CONFIRMATION
  );
  const hasOonFreeze = userFreezeReasons.includes(UserFreezeReason.OON_PLAN);
  const hasOtherFreeze =
    userFreezeReasons.includes(UserFreezeReason.OTHER) ||
    userFreezeReasons.includes(UserFreezeReason.PATIENT_ELIGIBILITY_NOT_FOUND);
  const renderCobFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <CoordinationOfBenefitsFreezeMessage />
      </Banner>
    );
  };
  const renderOtherFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <OtherFreezeMessage />
      </Banner>
    );
  };
  const renderOonFreezeBanner = () => {
    return (
      <Banner variant={'error'}>
        <OutOfNetworkFreezeMessage />
      </Banner>
    );
  };

  const content = (
    <>
      <LandingPageSectionContainer>
        <div css={aboveFoldContainerCss}>
          <div css={aboveFoldChildContainerCss}>
            <div css={{ display: 'flex' }}>
              <div css={aboveFoldCopyContainerCss}>
                <h1 css={aboveFoldTitleCss}>
                  <HeadlineText variant="H1">
                    Let us find your mental health provider.
                  </HeadlineText>
                </h1>
                <div css={aboveFoldCopyCss}>
                  <ContentText variant="body-large">
                    Whether you know what you need or aren't sure where to
                    start, we'll help you find the right fit — covered by your
                    insurance.
                  </ContentText>
                </div>
              </div>
            </div>
            <div css={searchContainerCss}>
              <div css={searchInputCss} className="mb-4">
                {showSearchByName ? (
                  <div css={searchByNameCss}>
                    <ProviderSearchByName />
                  </div>
                ) : (
                  <GetStartedContainer
                    shouldShowAnthemEAPExperience={
                      props.shouldShowAnthemEAPExperience
                    }
                  />
                )}
              </div>
              <Button
                onPress={() => {
                  if (!showSearchByName) {
                    trackProviderSearchStarted({
                      context: {
                        source: SearchEntry.LandingPageSearchName,
                      },
                    });
                  }
                  setShowSearchByName((curr) => !curr);
                }}
                variant="link"
              >
                {showSearchByName
                  ? 'Or discover new providers'
                  : 'Or look up providers by name'}
              </Button>
            </div>
          </div>
          <Image
            css={aboveFoldImageSmallScreenCss}
            src={imgWateringSrc}
            alt="Person watering plant"
            sizes="(max-width: 768px) 375px, 850px"
          />
        </div>
      </LandingPageSectionContainer>

      {props.topics && props.topics.length > 0 && (
        <ProviderSearchTopicCards topics={props.topics} />
      )}

      <LandingPageSectionContainer>
        <LandingPageSectionPair reverse={true}>
          <LandingPageSection>
            <h2 className="hlx-typography-subbody m-0 uppercase">
              Use your insurance
            </h2>
            <h2 className="m-0">
              <HeadlineText variant="H4">
                See how much you save on sessions
              </HeadlineText>
            </h2>
            <ContentText variant="body-large">
              Our average client saves 75% on sessions through Headway. We’ll
              work with your insurance so you can focus on your care, without
              worrying about cost.
            </ContentText>
            <div className="m-0 w-full">
              <LinkButton
                variant="brand"
                size="large"
                href="/does-my-insurance-cover-therapy"
                data-testid="doesMyInsuranceCoverTherapyLink"
              >
                Find your price
              </LinkButton>
            </div>
          </LandingPageSection>
          <LandingPageSection>
            <Image
              src={imgTeaSrc}
              alt="Two people drinking tea"
              sizes="(max-width: 768px) 375px, 850px"
            />
          </LandingPageSection>
        </LandingPageSectionPair>
      </LandingPageSectionContainer>

      <LandingPageSectionContainer>
        <LandingPageSection maxCenteredBreakpoint="tablet">
          <h2 className="hlx-typography-subbody m-0 uppercase">
            Nationwide access
          </h2>
          <h2 className="m-0">
            <HeadlineText variant="H4">
              Get mental health care, covered by insurance
            </HeadlineText>
          </h2>
          <ContentText variant="body-large">
            We partner with 45+ of the nation's top insurance plans to make sure
            you can get the affordable care you need. Today, our diverse network
            of providers offer both in-person and virtual care across 57+
            languages.
          </ContentText>
          <PayerPartners />
        </LandingPageSection>
      </LandingPageSectionContainer>

      <LandingPageSectionContainer id="how-it-works">
        <LandingPageSectionPair reverse={true}>
          <LandingPageSection>
            <h2 className="hlx-typography-subbody m-0 uppercase">
              Getting started
            </h2>
            <h2 className="m-0">
              <HeadlineText variant="H4">How it works</HeadlineText>
            </h2>
            <ContentText variant="body-large">
              <OrderedList>
                <ListItem>
                  <p>
                    <strong>Find the right fit</strong>
                  </p>
                  Share your preferences and we’ll filter through thousands of
                  therapists and psychiatrists to find your matches.
                </ListItem>
                <ListItem>
                  <p>
                    <strong>Get the in-network price</strong>
                  </p>
                  Add your insurance details so we can estimate your cost.
                </ListItem>
                <ListItem>
                  <p>
                    <strong>Book your session</strong>
                  </p>
                  Book right on Headway—we’ll handle everything from there.
                  You’ll only be billed after your session.
                </ListItem>
              </OrderedList>
            </ContentText>
            <div className="m-0 w-full">
              <GetStartedButton
                user={props.AuthStore.user}
                ctaCopy="Find your provider"
                id="market-how-it-works"
              />
            </div>
          </LandingPageSection>
          <LandingPageSection>
            <Image
              src={imgHikingSrc}
              alt="Two people hiking."
              sizes="(max-width: 768px) 375px, 850px"
            />
          </LandingPageSection>
        </LandingPageSectionPair>
      </LandingPageSectionContainer>

      <TwitterTestimonialCarousel />

      <LandingPageSectionContainer>
        <PrimaryBackground>
          <LandingPageSectionPair>
            <LandingPageSection>
              <h2 className="m-0">
                <HeadlineText>
                  Are you a mental health care provider or group practice?
                </HeadlineText>
              </h2>
              <ContentText variant="body-large">
                Offer affordable, in-network care without the administrative
                burden. So you can focus on what matters most.
              </ContentText>
            </LandingPageSection>
            <LandingPageSection>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: theme.space.xl,
                  [theme.media.small]: {
                    justifyContent: 'flex-end',
                    marginTop: 0,
                  },
                }}
              >
                <LinkButton
                  variant="brand"
                  size="large"
                  href="/for-providers"
                  aria-label="Learn more for providers and group practices"
                >
                  Learn more
                </LinkButton>
              </div>
            </LandingPageSection>
          </LandingPageSectionPair>
        </PrimaryBackground>
      </LandingPageSectionContainer>

      <Divider />

      <LandingPageSectionContainer>
        <LandingPageSection centered={true}>
          <h2 className="m-0">
            <HeadlineText variant="H3">Life can be hard.</HeadlineText>
          </h2>
          <ContentText variant="body-large">
            Finding someone who can make it easier shouldn’t be.
          </ContentText>
          <div className="m-0 w-full">
            <GetStartedButton
              css={{ marginRight: 'auto', marginLeft: 'auto' }}
              user={props.AuthStore.user}
              ctaCopy="Find your provider"
              id="market-footer"
            />
          </div>
        </LandingPageSection>
      </LandingPageSectionContainer>
    </>
  );

  if (isRemix) {
    return content;
  }

  return (
    <React.Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: sanitize(JSON.stringify(organizationStructuredData)),
        }}
      />
      <NextSeo
        title="Find Therapists Covered by Your Insurance"
        titleTemplate="Headway | %s"
        description="Find therapists near you who accept insurance. We'll help you find the right fit—and save you money. Get started today with online or in-person sessions."
        openGraph={{}} // empty object currently needed due to https://github.com/garmeeh/next-seo/issues/544
        canonical={getFullURLForPath('/')}
      />
      <PageWrapper
        banner={
          hasCobFreeze
            ? renderCobFreezeBanner()
            : hasOonFreeze
              ? renderOonFreezeBanner()
              : hasOtherFreeze
                ? renderOtherFreezeBanner()
                : undefined
        }
      >
        {content}
      </PageWrapper>
    </React.Fragment>
  );
};

const topicSearchSectionCss = css`
  &#topics-carousel {
    padding: 0 1.5rem 6rem;
  }
  display: grid;
  gap: ${theme.space.xl2} ${theme.space.base};
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'cards'
    'cta';

  ${theme.media.small} {
    grid-template-areas:
      'header pagination'
      'cards cards';
    grid-template-columns: 1fr 1fr;
  }
`;

const paginationCss = css`
  grid-area: pagination;
  display: flex;
  gap: ${theme.space.sm};
  align-items: center;
  justify-content: flex-end;

  ${theme.media.smallDown} {
    display: none;
  }
`;

const topicCardsCss = css`
  grid-area: cards;

  .carousel-inner {
    padding-bottom: ${theme.space.lg};
    --shadow-allowance: ${theme.space.lg};

    ${theme.media.smallDown} {
      padding-left: var(--shadow-allowance);
      padding-right: var(--shadow-allowance);
      margin-left: calc(-1 * var(--shadow-allowance));
      margin-right: calc(-1 * var(--shadow-allowance));
      margin-bottom: calc(-1 * var(--shadow-allowance));
    }
  }

  .carousel-item-wrapper {
    ${theme.media.smallDown} {
      min-width: 240px;
    }
  }
`;

const circleIconButtonCss = {
  '--color': helixTheme.color.foreground.primary,
  '&[disabled]': {
    '--color': helixTheme.color.foreground.disabled,
  },
  border: `1px solid var(--color)`,
  color: 'var(--color)',
};

export interface ProviderSearchTopicCardsProps {
  topics: ProviderSearchTopicResponse[];
}
export const ProviderSearchTopicCards = ({
  topics,
}: ProviderSearchTopicCardsProps) => {
  // TODO(nathanforce):
  // Commented this out in the interest of time. Ideally we hide cards that aren't visible
  //  so that they aren't tabable. This worked when using native el.scrollIntoView(),
  // but that function is not supported in Safari for horizontal (aka 'inline') scrolling.
  //  When I updated to the polyfill it somehow stopped firing the intersection observer.
  // I'll revisit this entire scrolling functionality once we launch.
  // const isAtLeastSmallViewport = useMediaQuery(theme.media.small);
  // React.useEffect(() => {
  //   if (!isAtLeastSmallViewport) {
  //     return;
  //   }

  //   const container = containerRef.current;

  //   if (!container) {
  //     return;
  //   }

  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       for (const entry of entries) {
  //         if (!entry.isIntersecting) {
  //           // entry.target.style.visibility = 'hidden';
  //         } else {
  //           // entry.target.style.visibility = 'visible';
  //         }
  //       }
  //     },
  //     {
  //       root: container,
  //       rootMargin: '-8px',
  //     }
  //   );

  //   const cards = Array.from(container.children);

  //   for (const card of cards) {
  //     observer.observe(card);
  //   }

  //   return () => {
  //     for (const card of cards) {
  //       observer.unobserve(card);
  //       card.style.removeProperty('visibility');
  //     }
  //   };
  // }, [isAtLeastSmallViewport]);

  const onTopicClick = (topic: ProviderSearchTopic) => {
    trackProviderSearchStarted({
      filters: { topics: [topic] },
      context: { source: SearchEntry.LandingPageTopicCarousel },
    });
  };
  const isAtLeastLargeViewport = useMediaQuery(theme.media.large);
  const isSmallViewport = useMediaQuery(theme.media.smallDown);

  return (
    <LandingPageSectionContainer id="topics-carousel">
      <div css={topicSearchSectionCss}>
        <div>
          <h2>
            <HeadlineText variant="H3">
              Find the right person for what you need
            </HeadlineText>
          </h2>
        </div>

        <Carousel
          renderControls={({ next, prev, hasNext, hasPrev }) => (
            <div css={paginationCss}>
              <LinkButton variant="secondary" size="medium" href="/topics">
                Explore all
              </LinkButton>
              <IconButton
                disabled={!hasPrev}
                size="small"
                css={circleIconButtonCss}
                onClick={prev}
              >
                <ChevronLeftOutlined
                  css={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
                <VisuallyHidden>Previous page</VisuallyHidden>
              </IconButton>
              <IconButton
                css={circleIconButtonCss}
                size="small"
                disabled={!hasNext}
                onClick={next}
              >
                <ChevronRightOutlined
                  css={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
                <VisuallyHidden>Next page</VisuallyHidden>
              </IconButton>
            </div>
          )}
          items={topics.map((topic) => {
            return (
              <div
                css={{
                  padding: isSmallViewport ? 0 : `0 ${theme.space.sm}`,
                  height: '100%',
                }}
              >
                <ProviderSearchTopicCard onClick={onTopicClick} topic={topic} />
              </div>
            );
          })}
          itemsPerPage={isAtLeastLargeViewport ? 4 : 3}
          css={topicCardsCss}
          gap={isSmallViewport ? theme.space.base : '0px'}
        />

        <div className="tablet:hidden col-span-full grid [grid-area:cta]">
          <LinkButton variant="secondary" size="medium" href="/topics">
            Explore all
          </LinkButton>
        </div>
      </div>
    </LandingPageSectionContainer>
  );
};

const MEDIA_SMALL_TO_DESKTOP = `@media (min-width: ${theme.breakpoints.small}px) and (max-width: 1024px)`;

const aboveFoldContainerCss = css`
  display: grid;
  grid-template-columns: auto max(400px);
  gap: var(--hlx-space-x4);
  margin-bottom: ${theme.space.lg};
  ${theme.media.smallDown} {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    grid-template-columns: 1fr;
  }
`;
const aboveFoldChildContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xl2};
  height: 100%;
  width: 100%;
`;

const aboveFoldCopyCss = css`
  width: 72%;
  ${theme.media.smallDown} {
    width: 100%;
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    width: 80%;
  }
`;
const aboveFoldCopyContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.xl2};
`;
const aboveFoldTitleCss = css`
  margin: 0;
  text-wrap: balance;
  max-width: 70ch;

  .legacy-global-css & {
  max-width: 25ch;
  max-width: 25ch;
`;

const aboveFoldImageSmallScreenCss = css`
  ${theme.media.smallDown} {
    margin-top: ${theme.space.xl5};
  }
  ${MEDIA_SMALL_TO_DESKTOP} {
    display: none;
  }
`;

const searchByNameCss = css`
  width: 500px;
  ${theme.media.smallDown} {
    width: 100%;
  }
`;
const searchContainerCss = css`
  margin: 0;
  width: fit-content;
  ${theme.media.smallDown} {
    width: 100%;
  }
`;
const searchInputCss = css`
  width: 100%;
`;

export default withStores(IndexPageImpl);
