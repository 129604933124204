import React from 'react';

import { Logo, Logos } from '@headway/ui/landing';

export const PayerPartners: React.FC = () => {
  // TODO: Add back payer logos when approved
  // TODO: Add BCBS once we resolve how to include specific Blues
  return (
    <React.Fragment>
      <Logos>
        <Logo className="text-[#7d3f98]">
          Aetna
          {/*<Aetna height={50} width={150} />*/}
        </Logo>
        <Logo className="text-[#0067A5]">
          Anthem
          {/*<Anthem height={50} width={150} />*/}
        </Logo>
        <Logo className="text-[#0982C6]">
          Cigna
          {/*<Cigna height={50} width={150} />*/}
        </Logo>
      </Logos>
      <Logos>
        <Logo className="text-[#008bcc]">
          Oxford
          {/*<UnitedOxford height={50} width={150} />*/}
        </Logo>
        <Logo className="text-[#008bcc]">
          UnitedHealthcare
          {/*<United height={50} width={150} />*/}
        </Logo>
        <Logo className="text-[#2854a1]">
          Oscar
          {/*<Oscar height={50} width={150} />*/}
        </Logo>
      </Logos>
    </React.Fragment>
  );
};
